@charset "UTF-8";

@use '../abstracts' as *;
@use '../layout' as *;

* {
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: base('background');
    font-family: 'Lato', 'Arial', sans-serif;
    color: base('black');
  }

  ul {
      list-style: none;
      margin: 0;
      padding: 0;
  }

  a {
      text-decoration: none;
      color: inherit;
  }