@use '../abstracts' as *;

.fill-white {
    fill: base('white');
}

.fill-grey {
    fill: base('grey');
}

.fill-red {
    fill: base('red');
}

.masthead {
    position: fixed;
    background: base('black');
    width: 100%;
    box-shadow: 1px 4px 12px base('shadow');
    z-index: 100;
}

.nav-wrapper {
    padding: padding('default');
    max-width: prop('wrapper');
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @include mq(mobile) {
        flex-wrap: nowrap;
    }
}

.logo__brand {
    display: block;
    max-width: 140px;
    width: prop('full');
}

.search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: prop('full');
    margin-top: margin('medium');
    z-index: 1000;
    order: 1;

    // &:focus-within {
    //     border: 1px solid blue;
    // }
    
    & input {
        border: none;
        border-radius: 50px;
        width: prop('full');
        padding: padding('search');
        background: base('white');
    }

    @include mq(mobile) {
        order: 0;
        max-width: 400px;
        margin-top: 0;
    }

    @include mq(tablet) {
        order: 0;
        max-width: 450px;
    }

    @include mq(laptop) {
        order: 0;
        max-width: 550px;
    }
}

.label-hidden {
    position: absolute;
    z-index: 1;
    visibility: hidden;
}

.icon__search {
    position: absolute;
    width: 16px;
    /*margin: 0 margin('xsmall');*/
    left: .75em;
}

.input__search:focus {
     outline: 1px solid base('blue');
}

.input__search:focus-visible {
    outline: 3px solid base('blue');
}

.btn__clear {
    position: absolute;
    border: none;
    background: none;
    padding: 0;
    width: 16px;
    height: 16px;
    right: .5em;
    cursor: pointer;
    display: none;
}

.visible {
    display: block;
}
