h1, h2, h3, h4, h5, h6 {
  line-height: 1.5em;
  letter-spacing: 1px;
  margin: 0;
}

p {
  margin-bottom: 1em;
}
p:last-of-type {
  margin-bottom: 0;
}

.page-wrapper {
  max-width: 1440px;
  position: relative;
  z-index: 10;
  /*padding: 4em 1em;*/
}
.page-wrapper--padding {
  padding: 8em 1em;
}

.pokedex {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 3.125em;
}
@media only screen and (min-width: 640px) {
  .pokedex {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3.125em 2em;
  }
}
@media only screen and (min-width: 768px) {
  .pokedex {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3.125em 2em;
  }
}
@media only screen and (min-width: 1024px) {
  .pokedex {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3.125em 2em;
  }
}
@media only screen and (min-width: 1440px) {
  .pokedex {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 3.125em 2em;
  }
}

* {
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #EEE;
  font-family: "Lato", "Arial", sans-serif;
  color: #282828;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.poke-card {
  /*display: flex;
  flex-direction: column;
  align-items: center;*/
  text-align: center;
  border-radius: 20px;
  /*min-width: 250px;*/
  padding: 1.5em 0;
  /*height: 260px;*/
  cursor: pointer;
  background: #FFF;
  -webkit-animation: fadeIn 800ms;
  animation: fadeIn 800ms;
}
.poke-card:hover {
  box-shadow: 2px 2px 12px rgba(112, 112, 112, 0.25);
}
.poke-card:hover .poke-image {
  display: inline-block;
}
.poke-card:hover .poke-image--shiny {
  display: none;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.poke-image {
  display: none;
}

.poke-id {
  font-size: 0.8125em;
  font-weight: 900;
  color: #707070;
}
.poke-id::before {
  content: "#";
}

.poke-name {
  font-size: 1.5em;
  font-weight: 900;
  text-transform: capitalize;
  line-height: 1em;
  margin-top: 0.5em;
  margin-bottom: 1.25em;
}

.poke-type.ground {
  background: #DBC075;
}

.poke-type.rock {
  background: #6A4206;
}

.poke-type.grass {
  background: #94CAAD;
}

.poke-type.fire {
  background: #FEA655;
}

.poke-type.water {
  background: #78B6D9;
}

.poke-type.electric {
  background: #FFE040;
}

.poke-type.flying {
  background: #BAABFF;
}

.poke-type.dragon {
  background: #6B46FB;
}

.poke-type.psychic {
  background: #AD83B5;
}

.poke-type.ghost {
  background: #676DA4;
}

.poke-type.poison {
  background: #AD54B5;
}

.poke-type.steel {
  background: #E4E8F1;
}

.poke-type.normal {
  background: #B6AF8E;
}

.poke-type.ice {
  background: #A6D6D7;
}

.poke-type.fighting {
  background: #B13D31;
}

.poke-type.fairy {
  background: #E29DAC;
}

.poke-type.bug {
  background: #89C015;
}

.poke-type.dark {
  background: #32343C;
}

.poke-type.dragon, .poke-type.dark, .poke-type.fighting,
.poke-type.poison, .poke-type.ghost, .poke-type.rock {
  color: #FFF;
}

span.poke-type {
  display: inline-flex;
  align-items: center;
  font-size: 0.875em;
  font-weight: 700;
  line-height: 0.875em;
  height: 25px;
  margin-right: 0.5em;
  padding: 0 12px;
  border-radius: 8px;
}
span.poke-type:last-of-type {
  margin-right: 0;
}

.fill-white {
  fill: #FFF;
}

.fill-grey {
  fill: #707070;
}

.fill-red {
  fill: #FF5740;
}

.masthead {
  position: fixed;
  background: #282828;
  width: 100%;
  box-shadow: 1px 4px 12px rgba(112, 112, 112, 0.25);
  z-index: 100;
}

.nav-wrapper {
  padding: 1em;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (min-width: 640px) {
  .nav-wrapper {
    flex-wrap: nowrap;
  }
}

.logo__brand {
  display: block;
  max-width: 140px;
  width: 100%;
}

.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 1.25em;
  z-index: 1000;
  order: 1;
}
.search-wrapper input {
  border: none;
  border-radius: 50px;
  width: 100%;
  padding: 0.5em 3em;
  background: #FFF;
}
@media only screen and (min-width: 640px) {
  .search-wrapper {
    order: 0;
    max-width: 400px;
    margin-top: 0;
  }
}
@media only screen and (min-width: 768px) {
  .search-wrapper {
    order: 0;
    max-width: 450px;
  }
}
@media only screen and (min-width: 1024px) {
  .search-wrapper {
    order: 0;
    max-width: 550px;
  }
}

.label-hidden {
  position: absolute;
  z-index: 1;
  visibility: hidden;
}

.icon__search {
  position: absolute;
  width: 16px;
  /*margin: 0 margin('xsmall');*/
  left: 0.75em;
}

.input__search:focus {
  outline: 1px solid #3E9AEE;
}

.input__search:focus-visible {
  outline: 3px solid #3E9AEE;
}

.btn__clear {
  position: absolute;
  border: none;
  background: none;
  padding: 0;
  width: 16px;
  height: 16px;
  right: 0.5em;
  cursor: pointer;
  display: none;
}

.visible {
  display: block;
}

.footer {
  background: #282828;
  margin-top: auto;
  width: 100%;
  color: #FFF;
}

.footer-wrapper {
  padding: 1em;
  max-width: 1440px;
  text-align: center;
}

/*
span.poke-type {
    display: inline-block;
}


span.poke-type.water {
    background-color: #78B6D9;
}

h1.example.water {
    background-color: #78B6D9;
}*/
/*# sourceMappingURL=index.13dcaf8c.css.map */
