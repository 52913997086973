@use '../abstracts' as *;

.footer {
    background: base('black');
    margin-top: auto;
    width: 100%;
    color: base('white');
}

.footer-wrapper {
    padding: padding('default');
    max-width: prop('wrapper');
    text-align: center;
}

